<template>
  <div id="services">
    <section class="container">
      <h1 class="title">Services</h1>
      <section id="process">
        <div class="row">
          <div class="col-12 col-sm-3 topBorder processItem">
            <span class="stepCount">01</span>
            <h2>{{ services.service_one.title }}</h2>
            <p>
              {{ services.service_one.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 topBorder processItem">
            <span class="stepCount">02</span>
            <h2>{{ services.service_two.title }}</h2>
            <p>
              {{ services.service_two.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 topBorder processItem">
            <span class="stepCount">03</span>
            <h2>{{ services.service_three.title }}</h2>
            <p>
              {{ services.service_three.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 topBorder rightBorder processItem">
            <span class="stepCount">04</span>
            <h2>{{ services.service_four.title }}</h2>
            <p>
              {{ services.service_four.description }}
            </p>
          </div>
        </div>
        <div class="row flex-row-reverse">
          <div class="col-12 col-sm-3 bottomBorder rightBorder processItem">
            <span class="stepCount">05</span>
            <h2>{{ services.service_five.title }}</h2>
            <p>
              {{ services.service_five.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 bottomBorder processItem">
            <span class="stepCount">06</span>
            <h2>{{ services.service_six.title }}</h2>
            <p>
              {{ services.service_six.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 bottomBorder processItem">
            <span class="stepCount">07</span>
            <h2>{{ services.service_seven.title }}</h2>
            <p>
              {{ services.service_seven.description }}
            </p>
          </div>
          <div class="col-12 col-sm-3 bottomBorder processItem">
            <span class="stepCount">08</span>
            <h2>{{ services.service_eight.title }}</h2>
            <p>
              {{ services.service_eight.description }}
            </p>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Services',
  data () {
    return {
      services : []
    }
  },
  metaInfo: {
    title: 'Our Services'
  },
  created () {
    this.axios.get(this.$hostname + '/api/service?populate=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      this.services = response.data.data.attributes
      console.log(this.services)
    })
  }
}
</script>
